import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import * as stylesDashboard from '../styles/dashboard.module.css';
import DownloadForm from '../components/downloadForm';
import { FeatureList, GlobalTypography, TitleH1Typography, TitleH2Typography } from '../components/common/GlobalTypography';
import Seo from '../components/seo';
import Layout from '../components/layout';

export default function RegisterProduct() {
  const [isDownloadFormOpen, setIsDownloadFormOpen] = useState(false);

  const openDownloadForm = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(['openForm', 'UhQXr8']);
  }

  return (
    <Layout>
      <Seo title="Register Product" />
      <div className={stylesDashboard.root}>

        <div className={stylesDashboard.content}>
          <TitleH1Typography
            text="Warmly WELCOME beloved customer!"
            fontSizeMode="default"
          />
          <TitleH2Typography
            text="If you have had bought our product and now looking for your valuable bonuses, I can tell you, YOU ARE AT THE RIGHT PLACE"
            fontSizeMode="default"
          />
          <TitleH1Typography
            text="By REGISTERING you get all these for free (When real value is $75)"
            fontSizeMode="default"
          />
          <FeatureList itemTexts={[
            "90 Days Product Replacement Protection - value $20",
            "First Line Access to new product launches - value $20",
            "Platinum level customer service - value $15",
            "Home Improvement E-BOOK pack - value $10"
          ]}
          />

          <GlobalTypography
            text="TOTAL VALUE $75, cool huh?"
            fontSizeMode="default"
          />
          <TitleH1Typography
            text="Home Improvement e-book pack you get all this"
            fontSizeMode="default"
          />
          <FeatureList itemTexts={[
            "Insider Tips on Keeping Your Kitchen Looking Flawless",
            "Home improvement by painting. When it comes to improving one’s home, perhaps one of the easiest, quickest and least expensive ways to create a stunning, significant impact to a home is to consider painting. Here we give tips what things you need to consider when planning improvements by painting.",
            "Everybody wants to stay healthy and live long, right? We give tips how to start improving your hearth health, help losing some pounds or just simply become more active."
          ]}
          />
          <div className={stylesDashboard.centerContent}>
            <StaticImage
              className={stylesDashboard.bookList}
              src="../images/book-list.svg"
              alt="Book List"
            />
          </div>
          <div className={stylesDashboard.btnContainer}>
            <StaticImage
              className={stylesDashboard.arrow}
              src="../images/arrow-right.svg"
              alt="Arrow Right"
            />
            <Button
              variant="text"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                fontSize: {
                  xl: "72px",
                  lg: "54px",
                  md: "43.2px",
                  sm: "36px",
                  xs: "21.6px",
                },
                fontWeight: 700,
                fontFamily: "Roboto",
                borderRadius: "10px",
                backgroundColor: "#4DC539",
                color: "#FFFFFF",
                width: {
                  xl: "630px",
                  lg: "472.5px",
                  md: "378px",
                  sm: "315px",
                  xs: "189px",
                },
                padding: "6px 0",
                "&:hover": {
                  backgroundColor: "#4DC539",
                }
              }}
              onClick={openDownloadForm}
            >
              {'Download'}
            </Button>
            <StaticImage
              className={stylesDashboard.arrow}
              src="../images/arrow-left.svg"
              alt="Arrow Left"
            />
          </div>
        </div>
      </div>
    </Layout>

  )
}

export const Head = () => <Seo title="Register Product" />

/*
window._klOnsite = window._klOnsite || [];
window._klOnsite.push(['openForm', 'UhQXr8']);
*/